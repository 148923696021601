import { defineStore } from "pinia";
import type { IProduct } from "~/types";

export interface OrderCart extends Object {
  item: IProduct;
  count: number;
}

export interface OrderEntry extends Object {
  email: string;
  phone: string;
  fullName: string;
  cart: OrderCart[];
  amount: {
    value: string;
    currency: string;
  };
  isPickup: boolean;
  infoDelivery: {
    address: string;
    coords: any;
    isPrivateHouse: false;
    apartmentNumber: string;
    deliveryDate: string;
    deliveryTime: {
      timeFrom: string;
      timeTo: string;
    };
    comment: string;
    priceDelivery: {
      value: string;
      currency: string;
    };
  };
}

export const useOrderStore = defineStore("order", {
  state: (): {
    order: OrderEntry;
  } => ({
    order: {
      email: "",
      phone: "",
      fullName: "",
      isPickup: true,
      cart: [],
      amount: {
        value: "",
        currency: "RUB",
      },
      infoDelivery: {
        address: "",
        coords: [],
        isPrivateHouse: false,
        apartmentNumber: "",
        deliveryDate: "",
        deliveryTime: {
          timeFrom: "",
          timeTo: "",
        },
        comment: "",
        priceDelivery: {
          value: "",
          currency: "RUB",
        },
      },
    },
  }),
  actions: {
    clearOrder() {
      this.order.email = "";
      this.order.fullName = "";
      this.order.phone = "";
      this.order.cart = [];
      this.order.amount.value = "";
      this.order.isPickup = true;
      this.order.infoDelivery = {
        address: "",
        coords: [],
        isPrivateHouse: false,
        apartmentNumber: "",
        deliveryDate: "",
        deliveryTime: {
          timeFrom: "",
          timeTo: "",
        },
        comment: "",
        priceDelivery: {
          value: "",
          currency: "RUB",
        },
      };
    },
    setOrder(order: OrderEntry) {
      if (Object.keys(order).length > 0) {
        this.order.email = order.email;
        this.order.fullName = order.fullName;
        this.order.phone = order.phone;
        this.order.cart = order.cart;
        this.order.amount.value = order.amount?.value || "";
        this.order.isPickup = order.isPickup;
        this.order.infoDelivery.address = order.infoDelivery.address;
        this.order.infoDelivery.coords = order.infoDelivery.coords;
        this.order.infoDelivery.isPrivateHouse =
          order.infoDelivery.isPrivateHouse;
        this.order.infoDelivery.apartmentNumber =
          order.infoDelivery.apartmentNumber;
        this.order.infoDelivery.deliveryDate = order.infoDelivery.deliveryDate;
        this.order.infoDelivery.deliveryTime = order.infoDelivery.deliveryTime;
        this.order.infoDelivery.comment = order.infoDelivery.comment;
      }
    },
    clearOrderCart() {
      this.order.cart = [];
    },
    setAmount(amount: any) {
      this.order.amount.value = amount;
    },
    setOrderCart(cart: OrderCart[]) {
      this.order.cart = cart;
    },
    setEmail(email: string) {
      this.order.email = email;
    },
    setFullName(fullName: string) {
      this.order.fullName = fullName;
    },
    setPhone(phone: string) {
      this.order.phone = phone;
    },
    setIsPickup(isPickup: boolean) {
      this.order.isPickup = isPickup;
    },
    setInfoDelivery(infoDelivery: any) {
      this.order.infoDelivery.address = infoDelivery.address;
      this.order.infoDelivery.coords = infoDelivery.coords;
      this.order.infoDelivery.isPrivateHouse = infoDelivery.isPrivateHouse;
      this.order.infoDelivery.apartmentNumber = infoDelivery.apartmentNumber;
      this.order.infoDelivery.deliveryDate = infoDelivery.deliveryDate;
      this.order.infoDelivery.deliveryDate = infoDelivery.deliveryDate;
      this.order.infoDelivery.comment = infoDelivery.comment;
    },
  },
});
