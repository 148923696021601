export const keyValues = Object.freeze({
    enter: 'Enter',
    tab: 'Tab',
    delete: 'Delete',
    esc: 'Escape',
    space: 'Space',
    up: 'ArrowUp',
    down: 'ArrowDown',
    left: 'ArrowLeft',
    right: 'ArrowRight',
    end: 'End',
    home: 'Home',
    del: 'Delete',
    backspace: 'Backspace',
    insert: 'Insert',
    pageup: 'PageUp',
    pagedown: 'PageDown',
    shift: 'Shift',
  })

  export function createRange (length: number, start = 0): number[] {
    return Array.from({ length }, (v, k) => start + k)
  }
  
  export function toKebabCase (str = '') {
    return str
      .replace(/[^a-z]/gi, '-')
      .replace(/\B([A-Z])/g, '-$1')
      .toLowerCase()
  }
  

  export default function parsePrice (price: any) {

    const number = parseInt(price)

    const isDecimal = (number % 1 !== 0);
    // Форматируем целую часть числа, добавляя разделители тысяч
    const formattedIntegerPart = number.toLocaleString('ru-Ru', {
      useGrouping: true,
      maximumFractionDigits: 0,
    });

    // Если число было дробным, добавляем обратно десятичную часть
    if (isDecimal) {
      const decimalPart = (number % 1).toFixed(2).substring(1);
      return formattedIntegerPart + decimalPart;
    }
  
    return formattedIntegerPart;
  }

export function getCategoriesTree(categories: any) {
    if (categories.value && categories.value.length) {
      let sortCategory = categories.value.sort((a, b) => a.name.localeCompare(b.name));
      const tree = Object.fromEntries(
        sortCategory.map((n: any) => [
          n["public_id"],
          {
            parent_id: n.parent_id,
            label: n.name,
            image: n.imageUrl,
            icon: n.iconUrl,
            children: [],
            id: n.public_id,
            priority: n.priority
          },
        ])
      );
      let filterTree =  Object.values(tree).filter(
        (n) => !(tree[n["parent_id"]] && tree[n["parent_id"]].children.push(n))
      );
      return filterTree.sort((a, b) => a.priority - b.priority);

    }
    return [];

}