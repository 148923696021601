import { defineStore } from "pinia";

export interface CategoryStore extends Object {
  categories: [];
}

export const useCategoryStore = defineStore("category-store", {
  state: (): { categories: CategoryStore[] } => ({
    categories: [],
  }),
  actions: {
    clearCategoryStoreCategory() {
      this.categories = [];
    },

    setCategoryStore(categories: CategoryStore[]) {
      this.categories = categories;
    },
  },
});
