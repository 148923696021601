import { defineStore } from "pinia";

export interface FavoritesStore {
  productId: string;
}

export const useFavoritesStore = defineStore("favorites-store", {
  state: () => ({
    favorites: [] as FavoritesStore[],
  }),
  actions: {
    clearFavoritesStore() {
      this.favorites = [];
      this.saveToLocalStorage();
    },
    addFavoriteProduct(productId: string) {
      const existingFavorite = this.favorites.find(
        (element) => element.productId === productId
      );

      if (existingFavorite) {
        this.removeFavoriteProduct(productId);
      } else {
        this.favorites.push({ productId: productId });
        this.saveToLocalStorage();
      }
    },
    removeFavoriteProduct(productId: string) {
      const index = this.favorites.findIndex(
        (element) => element.productId === productId
      );

      if (index !== -1) {
        this.favorites.splice(index, 1);
        this.saveToLocalStorage();
      }
    },
    setFavoritesStore(favorites: FavoritesStore[]) {
      this.favorites = favorites;
      this.saveToLocalStorage();
    },
    saveToLocalStorage() {
      localStorage.setItem(
        LOCALSTORAGE_KEYS.favoritesStore,
        JSON.stringify(this.favorites)
      );
    },
  },
});
