<template>
  <section
    class="w-[90%] h-148 px-4 lg:px-0 mx-auto"
    style="
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(72, 80, 112, 0.16);
      border-radius: 16px;
    "
  >
    <div
      class="w-full lg:w-195 mx-auto pt-25 flex flex-col justify-center items-center"
    >
      <p-icon-logo-black class="h-42 w-42" />
      <div class="text-10xl text-primary-400 font-lato">Позитив</div>

      <div
        class="mt-8 lg:mt-16 text-base lg:text-lg text-primary-400 font-lato leading-5 text-center"
      >
        Сайт находится в разработке, но вы можете приобрести товары по адресу

        <div class="mt-5 inline-block lg:block">
          <span class="text-secondary-400">
            Гулрыпшский район, село Мачара, Абжуйское шоссе, 124
          </span>
          <span> или просмотреть нас в </span>
          <span class="text-secondary-400">
            <a
              href="https://instagram.com/positive_group_?igshid=MzRlODBiNWFlZA=="
              target="__blank"
            >
              Instagram
            </a>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>
