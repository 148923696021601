export const LOCALSTORAGE_KEYS = {
    authToken: 'auth._token.local',
    viewedProduct: 'viewed_product',
    categoryStore: 'category-store',
    favoritesStore: 'favorites_store',
    cart: 'positive-cart',
    order: 'positive-order',
  };
  
  export const COOKIE_KEYS = {
    authToken: 'auth._token.local',
    searchHistory: 'search.history',
    moreButtonClickCount: 1,
  };
  
  export const monthNamesShort = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ];
  
  export const monthNames = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  
  export const weekDayNames = [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ];
  
  export const DEFAULT_DATE_FORMAT = "DD.MM.YYYY HH:mm";
  
  export const DEFAULT_CURRENCY = 'RUB';

  export const DEFAULT_CURRENCY_FORMAT = {
    separator: '',
    decimal: '.',
    precision: 2,
    errorOnInvalid: true,
  };