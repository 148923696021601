import { defineStore } from 'pinia'

export interface CartEntry extends Object {
    item: string
    total: number;
};

export type Cart = Record<string, CartEntry>

export const useCartStore = defineStore('cart', {
  state: (): { cart: Cart } => ({
    cart: {}
  }),
  actions: {
    clearCart() {
      this.cart = {}
    },
    setCart(cart) {
      this.cart = {...cart};
    },
  },
})