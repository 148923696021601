import { defineStore } from "pinia";

export interface RecentlyViewed extends Object {
  productId: string;
  createdAt: string;
}

export const useRecentlyViewedStore = defineStore("recently-viewed", {
  state: (): { recentlyViewed: RecentlyViewed[] } => ({
    recentlyViewed: [],
  }),
  actions: {
    clearRecentlyViewed() {
      this.recentlyViewed = [];
    },
    addViewedProduct(productId: string) {
      const foundProduct = this.recentlyViewed.findIndex(
        (item) => item.productId === productId
      );
      if (foundProduct === -1) {
        if (this.recentlyViewed.length >= 20) {
          this.recentlyViewed.shift(); //limit 20
        }

        this.recentlyViewed.push({
          productId: productId,
          createdAt: new Date(Date.now()).toISOString(),
        });
      } else {
        this.recentlyViewed[foundProduct].createdAt = new Date(
          Date.now()
        ).toISOString();
      }
    },
    setRecentlyViewed(recentlyViewed: RecentlyViewed[]) {
      this.recentlyViewed = recentlyViewed;
    },
  },
});
